<template>
  <base-layout>
    <template>
      <details-page-title :title="$t('components.pods.sunpods.details.headline')" :details-id="id">
        <MoreActionsDropdown
          :isDoorOpen="
            header.sunpod_details.assigned_edgebox
              ? header.sunpod_details.assigned_edgebox.is_door_open
              : false
          "
          :sunpodId="id"
          :isShowEdit="false"
          :variant="`emphasize`"
          @refresh="refreshPage"
        />
      </details-page-title>

      <details-page-header
        :name="getName"
        :badge="getBadge"
        :avatar="getAvatar"
        :tabs="tabs"
      />

      <div class="min-h-screen">
        <router-view />
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '@/components/ui/DetailsPageHeader.vue'

import { SunpodsConfig } from '@/config/SunpodsConfig'
import { EventBus } from '@/utils'
export default {
  name: 'ViewSunpodDetails',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    MoreActionsDropdown: () =>
      import('@/views/sunpods/MoreActionsDropdown.vue'),
  },
  async created() {
    this.fetchData()
  },

  data() {
    return {
      header: {},
      symbol: '$',

      fallbackText: '--',

      tabs: [
        {   text: this.$t(
            'components.pods.sunpods.details.tabs.profile'
          ), href: this.getTabHref(`profile`) },
        { text: this.$t(
            'components.pods.sunpods.details.tabs.vehicles'
          ), href: this.getTabHref(`vehicles`) },
        { text: this.$t(
            'components.pods.sunpods.details.tabs.logs'
          ), href: this.getTabHref(`logs`) },
        { text: this.$t(
            'components.pods.sunpods.details.tabs.alarmLogs'
          ), href: this.getTabHref(`alarm-logs`) },
        { text: this.$t(
            'components.pods.sunpods.details.tabs.camera'
          ), href: this.getTabHref(`camera`) },
      ],
    }
  },

  computed: {
    id() {
      return this.$route.params.id
    },
    getName() {
      return this.header.sunpod_details.name
    },
    getBadge() {
      return 'Sunpod'
    },

    getAvatar() {
      return ''
    },
    getStatus() {
      if (this.header && this.header.sunpod_details) {
        return this.header.sunpod_details.is_active
      } else {
        return false
      }
    },
    getStatusEndpoint() {
      return SunpodsConfig.api.update(this.id)
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/sunpods/${this.$route.params.id}/${path}`
    },
    async fetchData() {
      await this.$http
        .get(SunpodsConfig.api.update(this.id))
        .then((res) => (this.header = res.data))
    },
    async refreshPage() {
      this.fetchData()
      EventBus.$emit(SunpodsConfig.events.refresh)
    },
  },
}
</script>
